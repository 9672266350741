import './css/style.css'
import React, { Suspense, useEffect, useState, lazy } from "react";
import { BrowserRouter as Router, Route, useNavigate, Routes, useLocation, useParams, useNavigationType, createRoutesFromChildren, matchRoutes, createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Canvas } from '@react-three/fiber'
import { Bvh, useProgress } from "@react-three/drei";
import ReactDOM from 'react-dom/client'
import "./helpers/i18n";
import * as Sentry from "@sentry/react";

// string to be local if location.host is localhost, its staging if host includes samsung and production if not
const environment = location.host.includes('localhost') ? 'local' : location.host.includes('samsungretailexperience') ? 'staging' : 'production'
environment !== 'local' && Sentry.init({
	dsn: "https://fde6c43720dc1554ad978f15868afd52@o4504911370780672.ingest.us.sentry.io/4508321368506368",
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration(
			{
				maskAllText: true,
				blockAllMedia: true,
			}
		),
		Sentry.reactRouterV6BrowserTracingIntegration({
			useEffect: React.useEffect,
			useLocation,
			useNavigationType,
			createRoutesFromChildren,
			matchRoutes,
		}),
	],
	environment,
	// Tracing
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
// Sentry.replayIntegration({
// 	// NOTE: This will disable built-in masking. Only use this if your site has no sensitive data, or if you've already set up other options for masking or blocking relevant data, such as 'ignore', 'block', 'mask' and 'maskFn'.
// 	maskAllText: false,
// 	blockAllMedia: false,
// });
// if location.query has usr, set it to localstorage then load it again and set Sentry.setUser({ email:....
// make sure to use URLSearchParams to get the query
if (location.search.includes('usr')) {
	let params = new URLSearchParams(location.search)
	let usr = params.get('usr')
	localStorage.setItem('usr', usr)
}
if (localStorage.usr) {
	Sentry.setUser({ username: localStorage.usr });
}

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(
	createBrowserRouter,
);



const Room = lazy(() => import('./components/Room.jsx'));


import RoomSelection from './pages/Landing.jsx'
import { useAtom } from 'jotai';
import { sceneLoadingAtom, kitchenLoadedAtom, isDev, showingSummaryAtom, isEffect } from './state.js';

// import Overlay from './overlays/index.jsx';
const Overlay = lazy(() => import('./overlays/index.jsx'));

import useMeasure from 'react-use-measure';

import { MatomoProvider, createInstance, useMatomo } from '@datapunt/matomo-tracker-react'
import { useTranslation } from 'react-i18next';
import { kitchenAtom } from './helpers/configs.js';

import { Leva } from 'leva';
import { Effects, SetLoaded } from './helpers/effects.jsx';
import { konsole } from './helpers/utils.js';

function computeFOV(focalLength, sensorHeight) {
	const fov = 2 * Math.atan(sensorHeight / (2 * focalLength)) * (180 / Math.PI);
	return fov;
}

const fov = computeFOV(40, 24);

function Scene() {
	return <>
		<Room />
	</>
}


function Loader({ onLoaded }) {
	const { active, progress, errors, item, loaded, total } = useProgress();
	const [sceneLoading, setSceneLoading] = useAtom(sceneLoadingAtom)
	const { t } = useTranslation();
	const [called, setCalled] = useState(false)

	let setint = null
	let loadingDesc = 'Downloading Assets'
	let loadingDescs = [
		'Downloading Assets',
		'Loading Scene',
		'Setting up Lighting',
		'Loading Textures',
	]

	useEffect(() => {
		if (progress) {
			konsole.log('progresssssssssssssssssssssssssssssssssssssss', progress, active, loaded, total);
		}
		if (progress === 100 && !called && sceneLoading) {
			onLoaded();
			setSceneLoading(false)
			konsole.log('SCENE HAS BEEN LOADED ::::::;:::::::::;;;;::::::::::;;;;;');
			setCalled(true);
		}

	}, [progress])

	let num = parseInt(progress)
	return (
		<div className={`fixed inset-0 flex items-center justify-center overlay ${num === 100 ? ' done ' : ''} `} style={{ zIndex: '100' }}>
			<div className='absolute inset-0' style={{ zIndex: '1', opacity: 0.2, backgroundImage: 'url(/ui/bg.jpeg)', backgroundSize: 'cover' }} />
			<div className='fixed inset-0 flex flex-col justify-center bold text-center' style={{ zIndex: '2' }} >
				<div className=' px-4'>
					<div className='text-center text-xs'>{t('LOADING')}</div>
					<div className='text-xs'>{t(loadingDesc)}</div>
					<div className='text-center text-xs'>{num}%</div>
				</div>
			</div>
		</div>
	);
}


function CanvasDOM() {
	const [kitchen, setKitchen] = useAtom(kitchenAtom)
	const [isLoaded, setIsLoaded] = useAtom(kitchenLoadedAtom);
	const { t } = useTranslation();
	// get id from useparams 
	const { id } = useParams()
	setKitchen(id[id.length - 1])
	const [$el, $elBounds] = useMeasure();

	const goFullScreen = () => {
		const isMobile = () => {
			let first = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
			let second = navigator.userAgent.indexOf('Mobile') !== -1;
			let third = navigator.userAgent.indexOf('Tablet') !== -1;
			return first || second || third;
		}
		if (isMobile()) {

			const elem = document.documentElement;

			if (elem.requestFullscreen) {
				elem.requestFullscreen();
			} else if (elem.mozRequestFullScreen) { /* Firefox */
				elem.mozRequestFullScreen();
			} else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
				elem.webkitRequestFullscreen();
			} else if (elem.msRequestFullscreen) { /* IE/Edge */
				elem.msRequestFullscreen();
			}
		}
	}
	const { trackPageView, trackEvent } = useMatomo();
	const [showingSummary, setShowingSummary] = useAtom(showingSummaryAtom)

	useEffect(() => {
		trackPageView({
			documentTitle: 'Kitchen 1 Configurator'
		})
	}, [])
	return <>
		<div className="app" onClick={goFullScreen}>

			<Leva
				// theme={myTheme} // you can pass a custom theme (see the styling section)
				// fill // default = false,  true makes the pane fill the parent dom node it's rendered in
				flat // default = false,  true removes border radius and shadow
				// oneLineLabels // default = false, alternative layout for labels, with labels and fields on separate rows
				// hideTitleBar // default = false, hides the GUI header
				collapsed // default = false, when true the GUI is collpased
				hidden={!isDev} // default = false, when true the GUI is hidden
			/>
			<div id="canvas-holder" className={`flex-1 relative ${isLoaded ? 'canvas-ready' : ''}`} ref={$el}>
				{showingSummary || <div id="bespoke-rotate">
					<div className="bespoke-rotation-inner">
						<img src="/ui/rotate-sm.png" />
						<div>{t('Please rotate your device')}</div>
						<div>{t('This page is best viewed in landscape position')}</div>
					</div>
				</div>}
				{isLoaded && <Overlay />}
				<Suspense fallback={
					<Loader
						onLoaded={() => setIsLoaded(true)}
					/>
				}>
					<Canvas
						gl={{ alpha: false }}
						camera={{
							position: [2, -1, 6.6],
							fov,
						}}
						flat={true}
						style={{ position: 'absolute', left: 0, top: 0 }}
						width={$elBounds.width}
						height={$elBounds.height}
					>
						{isEffect && <Effects />}
						<SetLoaded />
						{/* <Bvh firstHitOnly> */}
						<Scene />
						{/* </Bvh> */}
					</Canvas>
				</Suspense>
			</div>
			{/* {isLoaded && <MobileHamburger />} */}



		</div>
	</>
}
function TestDom() {
	const location = useLocation();
	const navigate = useNavigate();
	const [isLoaded, setIsLoaded] = useAtom(kitchenLoadedAtom);

	const [$el, $elBounds] = useMeasure();
	return <>
		<div className="app">

			<div id="canvas-holder" className='flex-1 relative' ref={$el}>
				<Suspense fallback={<Loader /* drei loader*/ onLoaded={() => setIsLoaded(true)} />}>
					<Canvas shadows="percentage" gl={{ alpha: false }} camera={{
						position: [0, 0, 8],
						fov,
					}}
						style={{ position: 'absolute', left: 0, top: 0 }}
						width={$elBounds.width}
						height={$elBounds.height}
					>
						<Environment castShadow blur={false} background={false} preset={'warehouse'} />
						<ModelLoader url={`https://prj-ai.s3.amazonaws.com/dacor-app/${location.search.replace('?file=', '')}`} position={[0, 0, 0]} />
					</Canvas>
				</Suspense>
			</div>



		</div>
	</>
}


// function App() {

// 	const router = sentryCreateBrowserRouter(
// 		createRoutesFromChildren(
// 			<Route path="/*" exact Component={RoutedApp} />
// 		)
// 	);

// 	return (
// 		<RouterProvider router={router} />
// 	);
// }

function RoutedApp() {
	return (
		// <Router>
		<Routes>
			<Route path="/" exact Component={RoomSelection} />
			<Route path="/testcomm" element={<TestDom />} />
			<Route path="/:id" element={<CanvasDOM />} />
		</Routes>
		// </Router>
	);
}
function App() {
	const { t, i18n } = useTranslation();
	konsole.log('language', i18n.language);

	useEffect(() => {
		const loadAndTransformTranslations = async () => {
			try {
				// Replace this with your actual API call
				const response = await fetch(`https://dacor.ecommersive.com/api/translation?populate[0]=translation&locale=all`);
				const { data } = await response.json(); // or .blob() or whatever format it is in

				let translations = {
					en: {},
					fr: {}
				}

				data.forEach((item) => {
					let lng = item.attributes.locale
					let tr = item.attributes.translation
					tr.forEach(({ key, value }) => {
						translations[lng][key] = value
					})

				})

				i18n.addResourceBundle('en', 'translation', translations.en);
				i18n.addResourceBundle('fr', 'translation', translations.fr);


			} catch (error) {
				konsole.error('Failed to load and transform translations:', error);
			}
			let language = localStorage.lng || 'en'
			i18n.changeLanguage(language);
		};
		loadAndTransformTranslations();

	}, [])

	const router = sentryCreateBrowserRouter(
		createRoutesFromChildren(
			<Route path="/*" exact Component={RoutedApp} />
		)
	);
	return (
		<RouterProvider router={router} />
	);

}

let container

document.addEventListener('DOMContentLoaded', function (event) {
	const instance = createInstance({
		urlBase: 'https://commersive-matomo-a6jkk2mokq-uc.a.run.app/',
		siteId: 9,
		// userId: 'UID76903202', // optional, default value: `undefined`.
		// trackerUrl: 'https://LINK.TO.DOMAIN/tracking.php', // optional, default value: `${urlBase}matomo.php`
		// srcUrl: 'https://LINK.TO.DOMAIN/tracking.js', // optional, default value: `${urlBase}matomo.js`
		disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
		heartBeat: { // optional, enabled by default
			active: true, // optional, default value: true
			seconds: 10 // optional, default value: `15
		},
		linkTracking: true, // optional, default value: true
		// configurations: { // optional, default value: {}
		//   disableCookies: true,
		//   setSecureCookie: true,
		//   setRequestMethod: 'POST'
		// }
	})
	if (!container) {
		container = document.getElementById('root')
		const root = ReactDOM.createRoot(container)
		root.render(
			<MatomoProvider value={instance}>
				<App />
			</MatomoProvider>
		);
	}
});


export default App;